import { useState, ChangeEvent, FC, useRef } from "react";

import { Button, ButtonVariant, Icon, IconVariant, Text, TextVariant } from "@lookiero/react-ui-kit";
import useImportCSV, { FileItem } from "../../molecules/ImportCSV/_behaviours/useImportCSV";
import Spinner from "../../atoms/Spinner/Spinner";
import useValidateCSV from "../../molecules/ImportCSV/_behaviours/useValidateCSV";
import { generatePath, useNavigate } from "react-router-dom";
import useEnvironment from "../../../views/_behaviors/useEnvironment";
import Routes from "../../../_routing/routes";
import { ReactIntl } from "@lookiero/i18n.js";
import { PRICE_MANAGEMENT_I18N_PREFIX, PriceManagementI18n } from "../../../../shared/i18n/priceManagement";
import "./price-management.css";

const REQUIRED_COLUMNS = ["AT", "BE", "CH", "Color", "DE", "ES", "FR", "GB", "Group", "IT", "LU", "NL", "PT", "SE"];

interface ErrorElement {
  group: string;
  color: string;
  message: string;
}

interface PriceManagementProps {
  readonly onEditProductVariantsPrices: (data: FileItem[], onError: (error: ErrorElement[]) => void) => void;
}

const PriceManagement: FC<PriceManagementProps> = ({ onEditProductVariantsPrices }) => {
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { defaultLocale } = useEnvironment().internationalization;
  const importFile = useImportCSV();
  const {
    validateIsEmpty,
    validateMaxRows,
    validateRequiredColumns,
    validateNotEmptyValues,
    validateDuplicatedRows,
    validateCustomMethod,
  } = useValidateCSV();

  const handleFileUpload = async (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setIsLoading(true);
    importFile(event, (data) => {
      validateData(data);
    });
    if (hiddenFileInput.current) {
      hiddenFileInput.current.value = "";
    }
    setIsLoading(false);
  };

  const validateData = (data: FileItem[]) => {
    const isNotEmpty = validateIsEmpty(data);
    const hasTheRightAmountOfRows = validateMaxRows(data);
    const hasRequiredColumns = validateRequiredColumns(data, REQUIRED_COLUMNS);
    const hasNotEmptyValues = validateNotEmptyValues(data);
    const hasDuplicatedRows = validateDuplicatedRows(data);
    const hasCentesimalPrices = validateCustomMethod(
      data,
      (row: FileItem) => {
        return Object.keys(row).every((key) => {
          if (key !== "Group" && key !== "Color") {
            const value = row[key];
            if (value) {
              const price = parseFloat(value);
              if (!isNaN(price) && price % 1 !== 0) {
                if (price % 100 !== 0) {
                  return false;
                }
              } else {
                return true;
              }
            }
          }
          return true;
        });
      },
      "Some price is not centesimal",
    );

    if (
      isNotEmpty &&
      hasTheRightAmountOfRows &&
      hasRequiredColumns &&
      hasNotEmptyValues &&
      hasDuplicatedRows &&
      hasCentesimalPrices
    ) {
      onEditProductVariantsPrices(data, (error) => {
        navigate(generatePath(`/${Routes.PRICE_MANAGEMENT_OUTCOME}`, { locale: defaultLocale }), {
          state: { data: error },
        });
      });
    }
  };

  const handleClick = () => {
    if (!hiddenFileInput || !hiddenFileInput.current) {
      return false;
    }
    hiddenFileInput.current.click();
  };

  return (
    <div className="price-management-wrapper">
      {isLoading ? (
        <div className="spinner-wrapper">
          <Spinner />
        </div>
      ) : undefined}
      <div className="price-management-content">
        <Icon className="download-icon" variant={IconVariant.DOWNLOAD} />
        <Text variant={TextVariant.HEADING_3}>
          <ReactIntl.I18nMessage id={PriceManagementI18n.TITLE} prefix={PRICE_MANAGEMENT_I18N_PREFIX} />
        </Text>
        <input ref={hiddenFileInput} style={{ display: "none" }} type="file" onChange={handleFileUpload} />
        <Button variant={ButtonVariant.PRIMARY} onClick={handleClick}>
          <ReactIntl.I18nMessage id={PriceManagementI18n.IMPORT_BUTTON} prefix={PRICE_MANAGEMENT_I18N_PREFIX} />
        </Button>
      </div>
    </div>
  );
};

export default PriceManagement;
