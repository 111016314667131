import { useDispatch } from "@lookiero/messaging.js";
import { AsyncActionState } from "@lookiero/react-ui-kit";
import { useCallback, useState } from "react";
import EditProductPrice from "../../../core/domain/productPrice/command/editProductPrices/EditProductPrices";
import CreateNotification from "../../../core/domain/notification/command/createNotification/CreateNotification";
import NotificationLevel from "../../../core/domain/notification/model/NotificationLevel";

interface EditProductPriceData {
  [key: string]: string;
}

interface ErrorElement {
  group: string;
  color: string;
  message: string;
}

interface EditProductVariantsPricesFunction {
  readonly editProductVariantsPrices: (data: EditProductPriceData[], onError: (error: ErrorElement[]) => void) => void;
  readonly editProductVariantsPricesState: AsyncActionState;
}

const useEditProductVariantsPrices = (): EditProductVariantsPricesFunction => {
  const [editProductVariantsPricesState, setEditProductVariantsPricesState] = useState(AsyncActionState.DEFAULT);
  const dispatch = useDispatch();
  const editProductVariantsPrices = useCallback(
    async (data: EditProductPriceData[], onError: (error: ErrorElement[]) => void) => {
      try {
        setEditProductVariantsPricesState(AsyncActionState.PENDING);

        await dispatch(new EditProductPrice(data));

        dispatch(
          new CreateNotification({
            level: NotificationLevel.SUCCESS,
            content: `Todos los precios han sido editados correctamente.`,
          }),
        );
        setEditProductVariantsPricesState(AsyncActionState.SUCCESS);
      } catch (error) {
        onError(error as ErrorElement[]);

        setEditProductVariantsPricesState(AsyncActionState.ERROR);
      }
    },
    [dispatch],
  );

  return {
    editProductVariantsPrices,
    editProductVariantsPricesState,
  };
};

export default useEditProductVariantsPrices;
