import { CommandHandler } from "@lookiero/messaging.js";
import EditProductPrices from "./EditProductPrices";
import ProductRepository from "../../model/ProductPriceRepository";
import ProductPrice from "../../model/ProductPrice";

class EditProductPricesHandler implements CommandHandler<EditProductPrices> {
  private repository: ProductRepository;

  public constructor(repository: ProductRepository) {
    this.repository = repository;
  }

  public async execute(command: EditProductPrices): Promise<void> {
    const editProductPriceInstance = ProductPrice.editProductPrices(command);
    return this.repository.editProductPrice(editProductPriceInstance);
  }
}

export default EditProductPricesHandler;
