import React, { useEffect } from "react";
import PriceManagementTemplate from "../../component-library/templates/PriceManagement/PriceManagement";
import useEditProductVariantsPrices from "../_behaviors/useEditProductVariantsPrices";
import Routes from "../../_routing/routes";
import { useGetCurrentEmployee } from "../_behaviors/employeeProvider";
import { generatePath, useNavigate } from "react-router-dom";
import useEnvironment from "../_behaviors/useEnvironment";

const PriceManagement: React.FC = () => {
  const { editProductVariantsPrices } = useEditProductVariantsPrices();
  const employee = useGetCurrentEmployee();
  const navigate = useNavigate();
  const { defaultLocale } = useEnvironment().internationalization;
  const hasPriceManagementRole = employee?.hasPriceManagementRole() as boolean;

  useEffect(() => {
    if (!hasPriceManagementRole) {
      return navigate(generatePath(`/${Routes.PRODUCTS}`, { locale: defaultLocale }));
    }
  }, [hasPriceManagementRole, navigate, defaultLocale]);

  return <PriceManagementTemplate onEditProductVariantsPrices={editProductVariantsPrices} />;
};

export default PriceManagement;
