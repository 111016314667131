import { AggregateRoot } from "@lookiero/messaging.js";
import ProductPriceEdited from "./ProductPriceEdited";

interface ProductPrice {
  readonly [key: string]: string;
}

class ProductPrices extends AggregateRoot {
  public prices: ProductPrice[];

  public constructor(prices: ProductPrice[]) {
    super();
    this.prices = prices;
  }

  public static editProductPrices({ prices }: { prices: ProductPrice[] }): ProductPrices {
    const instance = new ProductPrices(prices);
    instance.record(new ProductPriceEdited(prices));

    return instance;
  }
}

export default ProductPrices;
