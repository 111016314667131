import { EventBus } from "@lookiero/messaging.js";
import HttpClient from "@src/shared/delivery/application/HttpClient";
import ProductPriceRepository from "../../../../domain/productPrice/model/ProductPriceRepository";
import ProductPrice from "../../../../domain/productPrice/model/ProductPrice";

class HttpProductPriceRepository implements ProductPriceRepository {
  private httpClient: HttpClient;
  private eventBus!: EventBus;

  public constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public async editProductPrice(productPrices: ProductPrice): Promise<void> {
    try {
      const response = await this.httpClient.patch("/edit-product-variant-prices", productPrices.prices);

      if (!response.ok) {
        const result = await response.json();
        throw result;
      }

      this.eventBus.publish(productPrices.recordedEvents());
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
}

export default HttpProductPriceRepository;
