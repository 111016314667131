import Visibility from "@src/core/domain/visibilityRule/model/Visibility";
import Price from "@src/core/projection/price/model/Price";
import Product from "@src/core/projection/product/model/Product";
import ProductPreview from "@src/core/projection/product/model/ProductPreview";
import ProductAvailabilityPreview from "@src/core/projection/product/model/ProductAvailabilityPreview";
import ProductVariantVisibilityPreview from "@src/core/projection/product/model/ProductVariantVisibilityPreview";

type ProductAttributesPerMarketDto = {
  readonly market: string;
  readonly is_visible: boolean;
  readonly price: {
    readonly amount: number;
    readonly currency: string;
    readonly discounted_prices: {
      readonly amount: number;
      readonly percentage: number;
      readonly start_date: string;
      readonly end_date: string;
    }[];
  };
};

export type ProductFeatureDto = {
  readonly id: string;
  readonly name: string;
  readonly type: string;
  readonly multiple: boolean;
  readonly unit: string | null;
  readonly values: {
    readonly id: string;
    readonly value: string;
    readonly qualifier: null | {
      readonly id: string;
      readonly name: string;
      readonly multiple: boolean;
      readonly type: string;
      readonly unit: string | null;
      readonly values: {
        readonly id: string;
        readonly value: string;
      }[];
      readonly required: boolean;
    };
  }[];
};

export type ProductVariantDto = {
  readonly id: string;
  readonly barcode: string;
  readonly reference: string;
  readonly stock: number;
  readonly is_visible: boolean;
  readonly season: number;
  readonly visibility: string;
  readonly media: {
    readonly id: string;
    readonly perspective: string;
    readonly url: string;
    readonly media_attributes:
      | {
          readonly commercial_use: boolean;
        }
      | undefined;
  }[];
  readonly color: {
    readonly id: string;
    readonly code: string;
    readonly hexadecimal: string;
    readonly name: string;
    readonly parent: string; // FIX: Parent is not being returned in projection
  };
  readonly size: {
    readonly id: string;
    readonly lookiero: string;
    readonly uk: string;
    readonly europe: string;
    readonly families: string[]; // FIX: families is not being returned in projection
  };

  readonly attributes_per_markets: ProductAttributesPerMarketDto[];
};

export type ProductDto = {
  readonly id: string;
  readonly channel: string;
  readonly group: string;
  readonly title: string;
  readonly media: {
    readonly id: string;
    readonly perspective: string;
    readonly url: string;
    readonly media_attributes:
      | {
          readonly commercial_use: boolean;
        }
      | undefined;
  }[];
  readonly family: {
    readonly id: string;
    readonly name: string;
    readonly category: string;
    readonly number: number;
  };
  readonly brand: {
    readonly id: string;
    readonly name: string;
    readonly origin: string;
  };
  readonly product_variants: ProductVariantDto[];
  readonly features: ProductFeatureDto[];
};

type ProductPreviewToAvailabilityFromDto = {
  readonly id: string;
  readonly group: string;
};

type ProductVariantPreviewToVisibilityFromDto = {
  readonly id: string;
  readonly reference: string;
};

const deriveVisibility = (
  productVisibility: Visibility,
  productVariantsAttributesPerMarket: ProductAttributesPerMarketDto[],
): Visibility => {
  if (productVisibility === ("" as Visibility)) {
    return productVariantsAttributesPerMarket.every((apm) => apm.is_visible)
      ? Visibility.VISIBLE
      : productVariantsAttributesPerMarket.every((apm) => !apm.is_visible)
        ? Visibility.NOT_VISIBLE
        : Visibility.PARTIALLY_VISIBLE;
  }

  if (productVisibility === Visibility.PARTIALLY_VISIBLE) {
    return productVisibility;
  }

  if (
    (productVisibility === Visibility.NOT_VISIBLE &&
      productVariantsAttributesPerMarket.some((apm) => apm.is_visible)) ||
    (productVisibility === Visibility.VISIBLE && productVariantsAttributesPerMarket.some((apm) => !apm.is_visible))
  ) {
    return Visibility.PARTIALLY_VISIBLE;
  }

  return productVisibility;
};

const parseVariantsData = (
  group: string,
  variants: ProductVariantDto[],
): {
  seasons: number[];
  stockBySize: Record<string, number>;
  price: Record<string, Price>;
  image: string;
  visibility: Visibility;
} => {
  const variantsData = {
    seasons: [] as number[],
    stockBySize: {} as Record<string, number>,
    price: {} as Record<string, Price>,
    image: "",
    visibility: "" as Visibility,
  };
  if (!Boolean(variants)) {
    return variantsData;
  }

  return variants.reduce((data, variant) => {
    const visibility = deriveVisibility(data.visibility, variant.attributes_per_markets);
    const seasons = [...new Set([...data.seasons, variant.season])];
    const stockBySize = {
      ...data.stockBySize,
      [variant.size.id]: (data.stockBySize[variant.size.id] || 0) + variant.stock,
    };
    const price =
      Object.keys(data.price).length > 0
        ? data.price
        : variant.attributes_per_markets.reduce(
            (acc, attr) => ({
              ...acc,
              [attr.market]: { market: attr.market, amount: attr.price.amount, currency: attr.price.currency },
            }),
            {} as Record<string, Price>,
          );

    return {
      ...data,
      seasons,
      stockBySize,
      price,
      visibility,
    };
  }, variantsData);
};
const parseVariantsDataToPricesByColor = (
  variants: ProductVariantDto[],
): {
  [key: string]: Record<string, Price>;
} => {
  const variantsData = {};
  if (!Boolean(variants)) {
    return variantsData;
  }

  return variants.reduce((data, variant) => {
    const colorCode = variant.color.code;
    const price = variant.attributes_per_markets.reduce(
      (acc, attr) => ({
        ...acc,
        [attr.market]: { market: attr.market, amount: attr.price.amount, currency: attr.price.currency },
      }),
      {} as Record<string, Price>,
    );

    return {
      ...data,
      [colorCode]: price,
    };
  }, variantsData);
};

export const productPreviewFromDto = (dto: ProductDto): ProductPreview => {
  const { price, stockBySize, visibility } = parseVariantsData(dto.group, dto.product_variants);
  const pricesByColor = parseVariantsDataToPricesByColor(dto.product_variants);

  return {
    id: dto.id,
    group: dto.group,
    name: dto.title,
    brand: dto.brand,
    price,
    stock: stockBySize,
    media: dto.media
      ? // TODO: fix naming convention
        // eslint-disable-next-line @typescript-eslint/naming-convention
        dto.media.map(({ id, url, perspective, media_attributes }) => ({
          id,
          url,
          perspective,
          colorCode: null,
          commercialUse: media_attributes ? Boolean(media_attributes.commercial_use) : false,
        }))
      : [],
    visibility,
    productVariants: dto.product_variants.map((variant) => ({
      id: variant.id,
      season: variant.season,
      color: variant.color,
      size: variant.size,
      stock: variant.stock,
      isVisible: variant.is_visible,
      price: pricesByColor[variant.color.code],
      visibility: variant.attributes_per_markets.reduce((acc, apm) => ({ ...acc, [apm.market]: apm.is_visible }), {}),
      media: variant.media
        ? // eslint-disable-next-line @typescript-eslint/naming-convention
          variant.media.map(({ id, url, perspective, media_attributes }) => ({
            id,
            url,
            perspective,
            colorCode: variant.color.code,
            commercialUse: media_attributes ? Boolean(media_attributes.commercial_use) : false,
          }))
        : [],
    })),
  };
};

export const productFromDto = (dto: ProductDto): Product => {
  const { seasons, price, stockBySize, visibility } = parseVariantsData(dto.group, dto.product_variants);

  return {
    id: dto.id,
    group: dto.group,
    name: dto.title,
    family: dto.family,
    brand: dto.brand,
    season: seasons,
    climatology: "",
    dateAdded: new Date(),
    channel: dto.channel,
    media: dto.media
      ? // eslint-disable-next-line @typescript-eslint/naming-convention
        dto.media.map(({ id, url, perspective, media_attributes }) => ({
          id,
          url,
          perspective,
          colorCode: null,
          commercialUse: media_attributes ? Boolean(media_attributes.commercial_use) : false,
        }))
      : [],
    price,
    visibility,
    stockBySize,
    productVariants: dto.product_variants.map((variant) => ({
      id: variant.id,
      reference: variant.reference,
      color: variant.color,
      size: variant.size,
      visibility: variant.visibility,
      stock: variant.stock,
      isVisible: variant.is_visible,
      season: variant.season,
      attributesPerMarkets: variant.attributes_per_markets.map(
        // eslint-disable-next-line @typescript-eslint/naming-convention
        ({ market, is_visible, price }: ProductAttributesPerMarketDto) => ({
          market,
          isVisible: is_visible,
          price: {
            amount: price.amount,
            currency: price.currency,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            discountedPrices: price.discounted_prices.map(({ amount, percentage, start_date, end_date }) => ({
              amount,
              percentage,
              startDate: start_date,
              endDate: end_date,
            })),
          },
        }),
      ),
      media: variant.media
        ? // eslint-disable-next-line @typescript-eslint/naming-convention
          variant.media.map(({ id, url, perspective, media_attributes }) => ({
            id,
            url,
            perspective,
            colorCode: variant.color.code,
            commercialUse: media_attributes ? Boolean(media_attributes.commercial_use) : false,
          }))
        : [],
    })),
    features: dto.features.map((feature) => ({
      id: feature.id,
      name: feature.name,
      type: feature.type,
      multiple: feature.multiple,
      unit: feature.unit,
      values: feature.values.map((value) => ({
        id: value.id,
        value: value.value,
        qualifier: value.qualifier,
      })),
    })),
  };
};

export const productPreviewToAvailabilityFromDto = (
  dto: ProductPreviewToAvailabilityFromDto,
): ProductAvailabilityPreview => {
  return {
    id: dto.id,
    group: dto.group,
  };
};

export const productVariantPreviewToVisibilityFromDto = (
  dto: ProductVariantPreviewToVisibilityFromDto,
): ProductVariantVisibilityPreview => {
  return {
    id: dto.id,
    reference: dto.reference,
  };
};
