/* eslint-disable @typescript-eslint/no-unused-vars */
import { Container } from "inversify";
import { BusInjection, registers } from "./helpers/registers";
import packageInfo from "../../../package.json";
import HttpEnvironmentFetcher from "../infrastructure/projection/environment/model/HttpEnvironmentFetcher";
import FetchHttpClient from "@src/shared/delivery/infrastructure/application/FetchHttpClient";
import Environment from "../projection/environment/model/Environment";
import JwtAuthFetcher from "@src/shared/delivery/infrastructure/application/JwtAuthFetcher";
import Uppy from "@uppy/core";
import XHRUpload from "@uppy/xhr-upload";
import Webcam from "@uppy/webcam";
import SentryLogger from "../infrastructure/application/logger/model/SentryLogger";
import { queryDependecies } from "./queries/queryDependencies";
import { bootstrapWithReact } from "@lookiero/messaging.js";
import { processManagerDependecies } from "./processManagers/processManagerDependencies";
import { fromStringToSymbol } from "./_data-transfrom/fromStringToSymbol";
import CdnMediaService from "../infrastructure/application/media/model/CdnMediaService";
import LocalStorageClient from "@src/shared/delivery/infrastructure/application/LocalStorageClient";
import HttpClient from "@src/shared/delivery/application/HttpClient";
import HttpAuthenticator from "../infrastructure/domain/employee/model/HttpAuthenticator";
import InMemoryEmployeeRepository from "@src/shared/infrastructure/domain/employee/model/InMemoryEmployeeRepository";
import { commandDependecies } from "./commands/commandDependencies";

interface Bootstrap {
  (): Promise<Container>;
}

let container: Container;

const bootstrap: Bootstrap = async () => {
  if (container) {
    return container;
  }
  container = new Container();

  const { register, registerConstantValue, registerDynamicValue, busInjection } = registers({ container });

  const environmentFetcher = new HttpEnvironmentFetcher(new FetchHttpClient());
  const environment = await environmentFetcher.fetch();

  registerDynamicValue({
    identifier: "Environment",
    func: () => ({
      ...environment,
    }),
  });

  register({
    identifier: "EmployeeRepository",
    dependencies: ["LocalStorageClient"],
    classInjection: InMemoryEmployeeRepository,
  });

  register({
    identifier: "JwtAuthFetch",
    dependencies: ["EmployeeRepository"],
    classInjection: JwtAuthFetcher,
  });

  registerDynamicValue({
    identifier: "Uppy",
    func: (context) => {
      const environment = context.container.get<Environment>(fromStringToSymbol("Environment"));
      const jwtAuthFetcher = context.container.get<JwtAuthFetcher>(fromStringToSymbol("JwtAuthFetch"));

      const uppy = new Uppy({
        restrictions: {
          allowedFileTypes: ["image/jpeg", "image/png", "video/mp4"],
        },
      });

      uppy.use(XHRUpload, {
        endpoint: `${environment.catalogBackUrl}/upload-media`,
        fieldName: "content",
      });

      uppy.use(Webcam, {
        mirror: false,
        modes: ["picture"],
        facingMode: "environment",
        videoConstraints: { width: 450, aspectRatio: 4 / 5 },
      });

      uppy.on("file-added", (file) => {
        const fileReader = new FileReader();

        fileReader.readAsDataURL(file.data);

        fileReader.onload = () => {
          const image = new Image();

          image.onload = () => {
            if (!file.name.includes("collage") && (image.width > 1200 || image.height > 1500)) {
              uppy.removeFile(file.id);
              return uppy.info(`${file.name} error: more than 1200 x 1500 dimension`);
            }
            if (file.type !== "video/mp4" && file.size >= 1572864) {
              uppy.removeFile(file.id);
              return uppy.info(`${file.name} error: more 1.5MB size`);
            }
          };

          image.src = fileReader.result as string;
        };
      });

      if (environment.featureToggle.jwtLoginEnabled) {
        uppy.on("upload", async (data) => {
          uppy.setState({
            xhrUpload: {
              headers: { ...(await jwtAuthFetcher.getJwtAuthorization()) },
            },
          });
        });
      }

      return uppy;
    },
  });

  registerDynamicValue({
    identifier: "Logger",
    func: (context) => {
      const environment = context.container.get<Environment>(fromStringToSymbol("Environment"));

      return new SentryLogger({
        environment: window.location.hostname,
        release: packageInfo.version,
        publicKey: environment.sentryPublicKey,
        projectId: environment.sentryProject,
      });
    },
  });

  register({ identifier: "HttpAuthenticatorClient", dependencies: [], classInjection: FetchHttpClient });

  registerDynamicValue({
    identifier: "HttpClient",
    func: (context) => {
      const environment = context.container.get<Environment>(fromStringToSymbol("Environment"));
      const jwtAuthFetch = context.container.get<JwtAuthFetcher>(fromStringToSymbol("JwtAuthFetch"));
      const fetcher = environment.featureToggle.jwtLoginEnabled ? jwtAuthFetch : undefined;
      return new FetchHttpClient(environment.catalogBackUrl, fetcher);
    },
  });

  registerDynamicValue({
    identifier: "MediaService",
    func: (context) => {
      const environment = context.container.get<Environment>(fromStringToSymbol("Environment"));

      return new CdnMediaService(environment.cdnUrl);
    },
  });

  registerDynamicValue({
    identifier: "LocalStorageClient",
    func: () => {
      return new LocalStorageClient("catalog_storage");
    },
  });

  registerDynamicValue({
    identifier: "HttpAutheticator",
    func: (context) => {
      const httpAuthenticatorClient = context.container.get<HttpClient>(fromStringToSymbol("HttpAuthenticatorClient"));
      const environment = context.container.get<Environment>(fromStringToSymbol("Environment"));

      return new HttpAuthenticator(httpAuthenticatorClient, environment.authApi);
    },
  });

  registerDynamicValue({
    identifier: "TranslationEndpoint",
    func: (context) => {
      const {
        internationalization: { apiKey, endpoint },
      } = context.container.get<Environment>(fromStringToSymbol("Environment"));

      return (locale: string) => `${endpoint}/translations/${locale}?key=${apiKey}&no-folding=true`;
    },
  });

  const queries = await queryDependecies({ container });
  const processManagers = await processManagerDependecies({ container });
  const commands = await commandDependecies({ container });

  const { commandBus, eventBus, uiEventBus, component } = bootstrapWithReact({
    queries,
    commands,
    processManagers,
  });

  registerConstantValue({ identifier: "DomainEventBus", value: eventBus });
  registerConstantValue({ identifier: "UIEventBus", value: uiEventBus });
  registerConstantValue({ identifier: "CommandBus", value: commandBus });
  registerConstantValue({ identifier: "MessagingRootComponent", value: component });

  busInjection({ identifiyer: "DomainEventBus", name: "NotificationRepository", type: BusInjection.EVENT_BUS });
  busInjection({ identifiyer: "DomainEventBus", name: "FashionLabsProductRepository", type: BusInjection.EVENT_BUS });
  busInjection({ identifiyer: "DomainEventBus", name: "FashionLabsModelRepository", type: BusInjection.EVENT_BUS });
  busInjection({
    identifiyer: "DomainEventBus",
    name: "FashionLabsProductVariantRepository",
    type: BusInjection.EVENT_BUS,
  });
  busInjection({ identifiyer: "DomainEventBus", name: "AvailabilityRepository", type: BusInjection.EVENT_BUS });
  busInjection({
    identifiyer: "DomainEventBus",
    name: "VisibilityRepository",
    type: BusInjection.EVENT_BUS,
  });
  busInjection({
    identifiyer: "DomainEventBus",
    name: "MediaRepository",
    type: BusInjection.EVENT_BUS,
  });
  busInjection({
    identifiyer: "DomainEventBus",
    name: "ErrorRepository",
    type: BusInjection.EVENT_BUS,
  });
  busInjection({
    identifiyer: "DomainEventBus",
    name: "EmployeeRepository",
    type: BusInjection.EVENT_BUS,
  });
  busInjection({
    identifiyer: "DomainEventBus",
    name: "HttpAutheticator",
    type: BusInjection.EVENT_BUS,
  });
  busInjection({
    identifiyer: "DomainEventBus",
    name: "OrderRepository",
    type: BusInjection.EVENT_BUS,
  });
  busInjection({
    identifiyer: "DomainEventBus",
    name: "FamilyRepository",
    type: BusInjection.EVENT_BUS,
  });
  busInjection({
    identifiyer: "DomainEventBus",
    name: "ProductPriceRepository",
    type: BusInjection.EVENT_BUS,
  });
  busInjection({
    identifiyer: "DomainEventBus",
    name: "FeatureValueRepository",
    type: BusInjection.EVENT_BUS,
  });

  busInjection({
    identifiyer: "CommandBus",
    name: "CreateNotificationWhenFashionLabsProductCreated",
    type: BusInjection.COMMAND_BUS,
  });
  busInjection({
    identifiyer: "CommandBus",
    name: "CreateNotificationWhenFashionLabsProductEdited",
    type: BusInjection.COMMAND_BUS,
  });
  busInjection({
    identifiyer: "CommandBus",
    name: "CreateNotificationWhenFashionLabsModelCreated",
    type: BusInjection.COMMAND_BUS,
  });
  busInjection({
    identifiyer: "CommandBus",
    name: "CreateNotificationWhenFashionLabsModelEdited",
    type: BusInjection.COMMAND_BUS,
  });
  busInjection({
    identifiyer: "CommandBus",
    name: "CreateNotificationWhenAvailabilityEdited",
    type: BusInjection.COMMAND_BUS,
  });
  busInjection({
    identifiyer: "CommandBus",
    name: "CreateNotificationWhenError",
    type: BusInjection.COMMAND_BUS,
  });
  busInjection({
    identifiyer: "CommandBus",
    name: "LogoutWhenUnAuthorizedEmployeeError",
    type: BusInjection.COMMAND_BUS,
  });
  busInjection({
    identifiyer: "CommandBus",
    name: "CreateEmployeeWhenAuthenticated",
    type: BusInjection.COMMAND_BUS,
  });
  busInjection({
    identifiyer: "CommandBus",
    name: "CreateNotificationWhenOrderItemAdded",
    type: BusInjection.COMMAND_BUS,
  });
  busInjection({
    identifiyer: "CommandBus",
    name: "CreateNotificationWhenOrderPlaced",
    type: BusInjection.COMMAND_BUS,
  });
  busInjection({
    identifiyer: "CommandBus",
    name: "CreateNotificationWhenOrderCreated",
    type: BusInjection.COMMAND_BUS,
  });
  busInjection({
    identifiyer: "CommandBus",
    name: "CreateNotificationWhenFamilyFeatureValuesCloned",
    type: BusInjection.COMMAND_BUS,
  });
  busInjection({
    identifiyer: "CommandBus",
    name: "CreateNotificationWhenFamilyFeatureValuesEdited",
    type: BusInjection.COMMAND_BUS,
  });

  return container;
};

export { bootstrap, container };
