import React from "react";
import classNames from "classnames";
import { Text, TextVariant } from "@lookiero/react-ui-kit";

import "./headerSection.css";

interface HeaderSectionProps {
  readonly isSticky: boolean;
  readonly className?: string;
  readonly title?: React.ReactNode;
  readonly subtitle?: string;
  readonly controls?: React.ReactNode;
  readonly classNameControl?: string;
  readonly children?: React.ReactNode;
}
const HeaderSection: React.FC<HeaderSectionProps> = ({
  isSticky,
  className,
  title,
  subtitle,
  controls,
  classNameControl,
  children,
}) => (
  <header
    className={classNames("section-header", className, {
      "section-header--sticky": isSticky,
    })}
  >
    <div className="section-header__header">
      {title || subtitle ? (
        <div className="section-header__summary">
          {title ? (
            <Text className="section-header__title" tag="h3" variant={TextVariant.HEADING_2}>
              {title}
            </Text>
          ) : null}
          {subtitle ? (
            <Text className="section-header__subtitle" tag="p" variant={TextVariant.HEADING_3}>
              {subtitle}
            </Text>
          ) : null}
        </div>
      ) : null}
      {controls && <div className={classNames("section-header__controls", classNameControl)}>{controls}</div>}
    </div>
    {children && <div className="section-header__content">{children}</div>}
  </header>
);

export default HeaderSection;
