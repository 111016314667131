import { DomainEvent } from "@lookiero/messaging.js";

interface ProductPrice {
  readonly [key: string]: string;
}

class ProductPriceEdited extends DomainEvent {
  public prices: ProductPrice[];

  public constructor(prices: ProductPrice[]) {
    super();
    this.prices = prices;
  }

  protected messageName(): string {
    return "ProductPriceEdited";
  }
}

export default ProductPriceEdited;
