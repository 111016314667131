import { QueryHandler } from "@lookiero/messaging.js";
import ProductPreview from "../../model/ProductPreview";
import ProductView from "../../model/ProductView";
import ListProducts from "./ListProducts";

class ListProductsHandler implements QueryHandler<ListProducts> {
  private readonly view: ProductView;

  public constructor(view: ProductView) {
    this.view = view;
  }

  public handle({
    search,
    market,
    families,
    brands,
    sizes,
    colors,
    seasons,
    date,
    page,
    perPage,
    featureValues,
    brandOrigins,
    visibility,
    hasStock,
    reference,
    segment,
  }: ListProducts): Promise<ProductPreview[]> {
    return this.view.searchByCriteria({
      groups: search,
      market,
      families,
      brands,
      sizes,
      colors,
      seasons,
      date,
      page,
      perPage,
      featureValues,
      brandOrigins,
      visibility,
      hasStock,
      reference,
      segment,
    });
  }
}

export default ListProductsHandler;
