/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, FC, ReactNode, useContext, useMemo } from "react";
import { VirtualizedList } from "@lookiero/react-ui-kit";
import HeaderSection from "./HeaderSection/HeaderSection";

import "./table.css";

interface TableContextProps {
  readonly isSticky: boolean;
  readonly isVirtualized: boolean;
}

interface TableProps {
  readonly children: ReactNode;
  readonly isSticky?: boolean;
  readonly isVirtualized?: boolean;
}

const initialValues: TableContextProps = {
  isSticky: false,
  isVirtualized: false,
};

const TableContext = createContext<TableContextProps>(initialValues);

const Table: FC<TableProps> = ({ children, isSticky = false, isVirtualized = false }) => (
  <TableContext.Provider value={{ isSticky, isVirtualized }}>
    <div className="table">{children}</div>
  </TableContext.Provider>
);

interface TableHeaderProps {
  readonly className?: string;
  readonly title?: ReactNode;
  readonly subtitle?: string;
  readonly controls?: ReactNode;
  readonly classNameControl?: string;
  readonly children: ReactNode;
}

const TableHeader: FC<TableHeaderProps> = ({ children, className, title, subtitle, controls, classNameControl }) => {
  const { isSticky = false } = useContext(TableContext);
  return (
    <HeaderSection
      isSticky={isSticky}
      className={className}
      title={title}
      subtitle={subtitle}
      controls={controls}
      classNameControl={classNameControl}
    >
      {children}
    </HeaderSection>
  );
};

type VirtualizedData = Record<string, any[]>;
type Data = any[];

interface BodyProps {
  readonly data: VirtualizedData | Data;
  readonly virtualizedClassName?: string;
  readonly dataCount?: number;
  readonly page?: number;
  readonly perPage?: number;
  readonly onPageChanged?: (page: number) => void;
  readonly itemsPerRow?: (width: number) => number;
  readonly estimatedItemHeight?: (width: number) => number;
  readonly children: (item: any) => ReactNode;
}

const TableBody: FC<BodyProps> = ({
  children,
  data,
  page,
  perPage,
  onPageChanged,
  itemsPerRow,
  estimatedItemHeight,
  dataCount,
  virtualizedClassName,
}) => {
  const { isVirtualized = false } = useContext(TableContext);
  const virtualizedDataListItemRendered = useMemo(
    () =>
      (children: (item: any) => ReactNode) =>
      (index: number): ReactNode => {
        const items = data as VirtualizedData;
        const pageForIndex = perPage && Math.floor(index / perPage) + 1;
        const canBeItemRender = data && perPage && pageForIndex;
        const item = canBeItemRender && items[pageForIndex] && items[pageForIndex][index % perPage];

        return item ? children(item) : null;
      },
    [data, perPage],
  );
  const dataListItemRendered = useMemo(
    () => (children: (item: any, index: number) => ReactNode) => {
      const items = data as Data;

      return items ? items.map((item, index) => children(item, index)) : null;
    },
    [data],
  );

  return isVirtualized ? (
    <VirtualizedList
      estimatedItemHeight={estimatedItemHeight as (width: number) => number}
      itemsPerRow={itemsPerRow as (width: number) => number}
      itemCount={dataCount as number}
      page={page as number}
      perPage={perPage as number}
      onPageChanged={onPageChanged as (page: number) => void}
      data={data as Record<string, unknown[]>}
      className={{ virtualizedList: virtualizedClassName }}
    >
      {virtualizedDataListItemRendered(children)}
    </VirtualizedList>
  ) : (
    <div>{dataListItemRendered(children)}</div>
  );
};

export { Table, TableHeader, TableBody };
