const PRICE_MANAGEMENT_OUTCOME_I18N_PREFIX = "catalog.app.price_management_outcome.";

const PriceManagementOutcomeI18n = {
  TITLE: "title", // Se ha producido un error en
  EXPORT_BUTTON: "export_button", // Exportar CSV
  GROUP: "group", // Grupo
  COLOR: "color", // Color
  ERROR: "error", // Mensaje
  CURRENCY_ES: "currency_es", // ES €
  CURRENCY_PT: "currency_pt", // PT €
  CURRENCY_IT: "currency_it", // IT €
  CURRENCY_FR: "currency_fr", // FR €
  CURRENCY_DE: "currency_de", // DE €
  CURRENCY_AT: "currency_at", // AT €
  CURRENCY_BE: "currency_be", // BE €
  CURRENCY_LU: "currency_lu", // LU €
  CURRENCY_NL: "currency_nl", // NL €
  CURRENCY_GB: "currency_gb", // UK £
  CURRENCY_SE: "currency_se", // SE Kr
  CURRENCY_CH: "currency_ch", // CH FS
};

export { PRICE_MANAGEMENT_OUTCOME_I18N_PREFIX, PriceManagementOutcomeI18n };
