import { ChangeEvent, useCallback } from "react";
import { useDispatch } from "@lookiero/messaging.js";
import * as XLSX from "xlsx";
import CreateNotification from "@src/core/domain/notification/command/createNotification/CreateNotification";
import NotificationLevel from "@src/core/domain/notification/model/NotificationLevel";

export interface FileItem {
  [key: string]: string;
}

type ImportCSVData = (
  event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  callback?: (data: FileItem[]) => void,
) => FileItem[];

const useImportCSV = (): ImportCSVData => {
  const dispatch = useDispatch();

  const importFile = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, callback?: (data: FileItem[]) => void) => {
      const files = (event.target as HTMLInputElement).files;
      const file = files ? files[0] : null;
      const reader = new FileReader();

      reader.onload = (event) => {
        if (!event.target) return [];
        try {
          const workbook = XLSX.read(event.target.result, { type: "binary" });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const data = XLSX.utils.sheet_to_json(sheet) as FileItem[];

          if (callback) {
            callback(data);
          }
        } catch (err) {
          console.error("File could not be read! Code " + err);
          dispatch(
            new CreateNotification({
              level: NotificationLevel.ERROR,
              content: (err as Error).message,
            }),
          );
        }
      };

      if (file) {
        reader.readAsArrayBuffer(file);
      }
      return [];
    },
    [dispatch],
  );

  return importFile;
};

export default useImportCSV;
