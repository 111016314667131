import EditAvailabilityHandler from "@src/core/domain/availability/command/editAvailability/EditAvailabilityHandler";
import ThrowErrorHandler from "@src/core/domain/error/command/throwError/ThrowErrorHandler";
import CreateFashionLabsModelHandler from "@src/core/domain/fashionLabsModel/command/createFashionLabsModel/CreateFashionLabsModelHandler";
import EditFashionLabsModelHandler from "@src/core/domain/fashionLabsModel/command/editFashionLabsModel/EditFashionLabsModelHandler";
import CreateFashionLabsProductHandler from "@src/core/domain/fashionLabsProduct/command/createFashionLabsProduct/CreateFashionLabsProductHandler";
import EditFashionLabsProductHandler from "@src/core/domain/fashionLabsProduct/command/editFashionLabsProduct/EditFashionLabsProductHandler";
import CreateFashionLabsProductVariantHandler from "@src/core/domain/fashionLabsProductVariant/command/createFashionLabsProductVariant/CreateFashionLabsProductVariantHandler";
import EditFashionLabsProductVariantHandler from "@src/core/domain/fashionLabsProductVariant/command/editFashionLabsProductVariant/EditFashionLabsProductVariantHandler";
import RemoveMediaHandler from "@src/core/domain/media/command/removeMedia/RemoveMediaHandler";
import CreateNotificationHandler from "@src/core/domain/notification/command/createNotification/CreateNotificationHandler";
import RemoveNotificationHandler from "@src/core/domain/notification/command/removeNotification/RemoveNotificationHandler";
import AddOrderItemHandler from "@src/core/domain/order/command/addItem/AddOrderItemHandler";
import CreateOrderHandler from "@src/core/domain/order/command/createOrder/CreateOrderHandler";
import PlaceOrderHandler from "@src/core/domain/order/command/placeOrder/PlaceOrderHandler";
import EditVisibilityHandler from "@src/core/domain/visibility/command/editVisibility/EditVisibilityHandler";
import HttpAvailabilityRepository from "@src/core/infrastructure/domain/availavility/model/HttpAvailabilityRepository";
import InMemoryErrorRepository from "@src/core/infrastructure/domain/error/model/InMemoryErrorRepository";
import HttpFashionLabsModelRepository from "@src/core/infrastructure/domain/fashionLabsModel/model/HttpFashionLabsModelRepository";
import HttpFashionLabsProductRepository from "@src/core/infrastructure/domain/fashionLabsProduct/model/HttpFashionLabsProductRepository";
import HttpFashionLabsProductVariantRepository from "@src/core/infrastructure/domain/fashionLabsProductVariant/model/HttpFashionLabsProductVariantRepository";
import HttpMediaRepository from "@src/core/infrastructure/domain/media/model/HttpMediaRepository";
import InMemoryNotificationRepository from "@src/core/infrastructure/domain/notification/model/InMemoryNotificationRepository";
import HttpOrderRepository from "@src/core/infrastructure/domain/order/model/HttpOrderRepository";
import HttpVisibilityRepository from "@src/core/infrastructure/domain/visibility/model/HttpVisibilityRepository";
import RemoveOrderHandler from "@src/core/domain/order/command/removeOrderItem/RemoveOrderItemHandler";
import CreateEmployeeHandler from "@src/core/domain/employee/command/create/CreateEmployeeHandler";
import LoginHandler from "@src/core/domain/employee/command/login/LoginHandler";
import LogoutHandler from "@src/core/domain/employee/command/logout/LogoutHandler";
import CloneFamilyFeatureValuesHandler from "@src/core/domain/family/command/cloneFamilyFeatureValues/CloneFamilyFeatureValuesHandler";
import CreateFamilyHandler from "@src/core/domain/family/command/createFamily/CreateFamilyHandler";
import EditFamilyFeatureValuesHandler from "@src/core/domain/family/command/editFamilyFeatureValues/EditFamilyFeatureValuesHandler";
import HttpFamilyRepository from "@src/core/infrastructure/domain/family/model/HttpFamilyRepository";
import CreateFeatureValueHandler from "@src/core/domain/featureValue/command/createFeatureValue/CreateFeatureValueHandler";
import HttpFeatureValueRepository from "@src/core/infrastructure/domain/featureValue/model/HttpFeatureValueRepository";
import HttpProductPriceRepository from "../../infrastructure/domain/productPrice/model/HttpProductPriceRepository";
import EditProductPriceHandler from "../../domain/productPrice/command/editProductPrices/EditProductPriceHandler";

const commandRegisterList = [
  {
    identifier: "ErrorRepository",
    dependencies: [],
    classInjection: InMemoryErrorRepository,
  },
  {
    identifier: "ThrowCoreErrorHandler",
    dependencies: ["ErrorRepository"],
    classInjection: ThrowErrorHandler,
  },
  {
    identifier: "NotificationRepository",
    dependencies: ["NotificationStorageClient"],
    classInjection: InMemoryNotificationRepository,
  },
  {
    identifier: "CreateNotificationHandler",
    dependencies: ["NotificationRepository"],
    classInjection: CreateNotificationHandler,
  },
  {
    identifier: "RemoveNotificationHandler",
    dependencies: ["NotificationRepository"],
    classInjection: RemoveNotificationHandler,
  },
  {
    identifier: "FashionLabsProductRepository",
    dependencies: ["HttpClient"],
    classInjection: HttpFashionLabsProductRepository,
  },
  {
    identifier: "FashionLabsProductVariantRepository",
    dependencies: ["HttpClient"],
    classInjection: HttpFashionLabsProductVariantRepository,
  },
  {
    identifier: "CreateFashionLabsProductHandler",
    dependencies: ["FashionLabsProductRepository"],
    classInjection: CreateFashionLabsProductHandler,
  },
  {
    identifier: "CreateFashionLabsProductVariantHandler",
    dependencies: ["FashionLabsProductVariantRepository"],
    classInjection: CreateFashionLabsProductVariantHandler,
  },
  {
    identifier: "FashionLabsModelRepository",
    dependencies: ["HttpClient"],
    classInjection: HttpFashionLabsModelRepository,
  },
  {
    identifier: "CreateFashionLabsModelHandler",
    dependencies: ["FashionLabsModelRepository"],
    classInjection: CreateFashionLabsModelHandler,
  },
  {
    identifier: "EditFashionLabsModelHandler",
    dependencies: ["FashionLabsModelRepository"],
    classInjection: EditFashionLabsModelHandler,
  },
  {
    identifier: "EditVisibilityHandler",
    dependencies: ["VisibilityRepository"],
    classInjection: EditVisibilityHandler,
  },
  {
    identifier: "EditFashionLabsProductVariantHandler",
    dependencies: ["FashionLabsProductVariantRepository"],
    classInjection: EditFashionLabsProductVariantHandler,
  },
  {
    identifier: "EditFashionLabsProductHandler",
    dependencies: ["FashionLabsProductRepository"],
    classInjection: EditFashionLabsProductHandler,
  },
  {
    identifier: "AvailabilityRepository",
    dependencies: ["HttpClient"],
    classInjection: HttpAvailabilityRepository,
  },
  {
    identifier: "VisibilityRepository",
    dependencies: ["HttpClient"],
    classInjection: HttpVisibilityRepository,
  },
  {
    identifier: "EditAvailabilityHandler",
    dependencies: ["AvailabilityRepository"],
    classInjection: EditAvailabilityHandler,
  },
  {
    identifier: "MediaRepository",
    dependencies: ["HttpClient"],
    classInjection: HttpMediaRepository,
  },
  {
    identifier: "RemoveMediaHandler",
    dependencies: ["MediaRepository"],
    classInjection: RemoveMediaHandler,
  },
  {
    identifier: "OrderRepository",
    dependencies: ["HttpClient"],
    classInjection: HttpOrderRepository,
  },
  {
    identifier: "AddOrderItemHandler",
    dependencies: ["OrderRepository"],
    classInjection: AddOrderItemHandler,
  },
  {
    identifier: "RemoveOrderHandler",
    dependencies: ["OrderRepository"],
    classInjection: RemoveOrderHandler,
  },
  {
    identifier: "CreateOrderHandler",
    dependencies: ["OrderRepository"],
    classInjection: CreateOrderHandler,
  },
  {
    identifier: "PlaceOrderHandler",
    dependencies: ["OrderRepository"],
    classInjection: PlaceOrderHandler,
  },
  {
    identifier: "LoginHandler",
    dependencies: ["EmployeeRepository", "HttpAutheticator"],
    classInjection: LoginHandler,
  },
  {
    identifier: "LogoutHandler",
    dependencies: ["EmployeeRepository"],
    classInjection: LogoutHandler,
  },
  {
    identifier: "CreateEmployeeHandler",
    dependencies: ["EmployeeRepository"],
    classInjection: CreateEmployeeHandler,
  },
  {
    identifier: "FamilyRepository",
    dependencies: ["HttpClient"],
    classInjection: HttpFamilyRepository,
  },
  {
    identifier: "CreateFamilyHandler",
    dependencies: ["FamilyRepository"],
    classInjection: CreateFamilyHandler,
  },
  {
    identifier: "EditProductPriceHandler",
    dependencies: ["ProductPriceRepository"],
    classInjection: EditProductPriceHandler,
  },
  {
    identifier: "ProductPriceRepository",
    dependencies: ["HttpClient"],
    classInjection: HttpProductPriceRepository,
  },
  {
    identifier: "CloneFamilyFeatureValuesHandler",
    dependencies: ["FamilyRepository"],
    classInjection: CloneFamilyFeatureValuesHandler,
  },
  {
    identifier: "EditFamilyFeatureValuesHandler",
    dependencies: ["FamilyRepository"],
    classInjection: EditFamilyFeatureValuesHandler,
  },
  {
    identifier: "FeatureValueRepository",
    dependencies: ["HttpClient"],
    classInjection: HttpFeatureValueRepository,
  },
  {
    identifier: "CreateFeatureValueHandler",
    dependencies: ["FeatureValueRepository"],
    classInjection: CreateFeatureValueHandler,
  },
];

export { commandRegisterList };
