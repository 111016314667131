import { useCallback } from "react";
import * as XLSX from "xlsx";

export interface FileItem {
  [key: string]: string;
}

type ExportCSVData = (data: FileItem[], filename: string) => void;

const useExportCSV = (): ExportCSVData => {
  const exportFile = useCallback((data: FileItem[], filename: string) => {
    /* generate worksheet and workbook */
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");

    /* create an XLSX file and try to save to filename.xlsx */
    XLSX.writeFile(workbook, `${filename}.csv`, { compression: true });
  }, []);

  return exportFile;
};

export default useExportCSV;
