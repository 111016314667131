import { Command } from "@lookiero/messaging.js";

interface EditProductPriceData {
  readonly [key: string]: string;
}

class EditProductPrices extends Command {
  public readonly prices: EditProductPriceData[];

  public constructor(prices: EditProductPriceData[]) {
    super();
    this.prices = prices;
  }

  public messageName(): string {
    return "EditProductPrices";
  }
}

export default EditProductPrices;
