const ROOT_I18N_PREFIX = "catalog.app.root.";

enum RootI18n {
  HUB_LOG_OUT = "hub_log_out",
  HEADER_MENU_CATALOG = "header.catalog",
  HEADER_MENU_FASHION_LABS = "header.fashion_labs",
  HEADER_MENU_CATEGORIES = "header.categories",
  HEADER_MENU_PRICE_MANAGEMENT = "header.price_management",
  HEADER_MENU_AVAILABILITY = "header.availability",
  HEADER_MENU_VISIBILITY = "header.visibility",
  HEADER_FASHION_LABS_PRODUCTS = "header.fashion_labs.products",
  HEADER_FASHION_LABS_MODELS = "header.fashion_labs.models",
}

export { ROOT_I18N_PREFIX, RootI18n };
