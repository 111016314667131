import FashionLabsProduct, {
  FashionLabsProductFeature,
  FashionLabsProductModelInfo,
  FashionLabsProductVariantFashionModel,
} from "@src/core/projection/fashionLabsProduct/model/FashionLabsProduct";
import FashionLabsProductPreview from "@src/core/projection/fashionLabsProduct/model/FashionLabsProductPreview";
import FashionLabsProductForImport from "@src/core/projection/fashionLabsProduct/model/FashionLabsProductForImport";
import { SellingPrice } from "@src/shared/domain/price/model/Price";

type ProductModelView = {
  readonly model_id: string;
  readonly size_id: string;
  readonly model_fitting: string;
};

export type FashionLabsProductDto = {
  readonly id: string;
  readonly title: string;
  readonly group: string;
  readonly note: string;
  readonly channels: string[];
  readonly product_model: ProductModelView;
  readonly media: {
    readonly id: string;
    readonly url: string;
    readonly perspective: string;
    readonly media_attributes:
      | {
          readonly commercial_use: boolean;
        }
      | undefined;
  }[];
  readonly brand: {
    readonly id: string;
    readonly name: string;
    readonly origin: string;
  };
  readonly family: {
    readonly id: string;
    readonly name: string;
    readonly number: number;
    readonly category: string;
    readonly segment: string;
  };
  readonly product_variants: {
    readonly id: string;
    readonly season: number;
    readonly publishable: boolean;
    readonly barcode: string;
    readonly reference: string;
    readonly color: {
      readonly id: string;
      readonly code: string;
      readonly hexadecimal: string;
      readonly name: string;
    };
    readonly size: {
      readonly id: string;
      readonly lookiero: string;
      readonly uk: string;
      readonly europe: string;
    };
    readonly fashion_model: {
      readonly id: string;
      readonly size_id: string;
      readonly fitting: string;
    };
    readonly media: {
      readonly id: string;
      readonly url: string;
      readonly perspective: string;
      readonly media_attributes:
        | {
            readonly commercial_use: boolean;
          }
        | undefined;
    }[];
    readonly selling_price_eur: number;
    readonly selling_price_gbp: number;
    readonly selling_price_sek: number;
    readonly selling_price_chf: number;
    readonly selling_prices: SellingPrice[];
  }[];
  readonly features: {
    readonly id: string;
    readonly name: string;
    readonly type: string;
    readonly multiple: boolean;
    readonly unit: string | null;
    readonly values: {
      readonly id: string;
      readonly value: string;
      readonly qualifier: null | {
        readonly id: string;
        readonly name: string;
        readonly multiple: boolean;
        readonly type: string;
        readonly unit: string | null;
        readonly values: {
          readonly id: string;
          readonly value: string;
        }[];
        readonly required: boolean;
      };
    }[];
  }[];
  readonly segment: string;
};

export type FashionLabsProductPreviewDto = {
  readonly group: string;
  readonly id: string;
  readonly brand: {
    readonly id: string;
    readonly name: string;
  };
  readonly family: {
    readonly id: string;
    readonly name: string;
    readonly category: string;
  };
};

export type ProductDtoToImportFashionLabsProduct = {
  readonly features: {
    readonly id: string;
    readonly name: string;
    readonly type: string;
    readonly multiple: boolean;
    readonly unit: string | null;
    readonly values: {
      readonly id: string;
      readonly value: string;
      readonly qualifier: null | {
        readonly id: string;
        readonly name: string;
        readonly multiple: boolean;
        readonly type: string;
        readonly unit: string | null;
        readonly values: {
          readonly id: string;
          readonly value: string;
        }[];
        readonly required: boolean;
      };
    }[];
  }[];
};
export const fashionLabsProductPreviewFromDto = (
  response: FashionLabsProductPreviewDto,
): FashionLabsProductPreview => ({
  id: response.id,
  group: response.group,
  brandName: response.brand.name,
  family: response.family,
});

export const fashionLabsProductFromDto = (response: FashionLabsProductDto): FashionLabsProduct => {
  const subfamilyFeature = response.features?.find((feature) => feature.name === "subfamily");
  const subfamilyValue = subfamilyFeature ? subfamilyFeature.values[0] : null;
  const subfamily = subfamilyValue ? subfamilyValue.value.replace("_", " ") : "";

  return {
    id: response.id,
    title: response.title,
    group: response.group,
    note: response.note || "",
    brand: response.brand,
    family: response.family,
    subfamily,
    channels: response.channels,
    productModel: response.product_model
      ? {
          modelId: response.product_model.model_id,
          sizeId: response.product_model.size_id,
          modelFitting: response.product_model.model_fitting,
        }
      : ({} as FashionLabsProductModelInfo),
    media: response.media
      ? response.media.map((media) => ({
          id: media.id,
          url: media.url,
          perspective: media.perspective,
          colorCode: null,
          commercialUse: media.media_attributes ? Boolean(media.media_attributes.commercial_use) : false,
        }))
      : [],
    productVariants: response.product_variants.map((variant) => ({
      id: variant.id,
      season: variant.season,
      publishable: !Boolean(variant.id) || variant.publishable, // New variants will come as publishables
      barcode: variant.barcode,
      reference: variant.reference,
      color: variant.color,
      size: variant.size,
      fashionModel: variant.fashion_model
        ? {
            id: variant.fashion_model.id,
            sizeId: variant.fashion_model.size_id,
            fitting: variant.fashion_model.fitting,
          }
        : ({} as FashionLabsProductVariantFashionModel),
      media: variant.media
        ? // eslint-disable-next-line @typescript-eslint/naming-convention
          variant.media.map(({ id, url, perspective, media_attributes }) => ({
            id,
            url,
            perspective,
            colorCode: variant.color.code,
            commercialUse: media_attributes ? Boolean(media_attributes.commercial_use) : false,
          }))
        : [],
      sellingPriceEur: variant.selling_price_eur,
      sellingPriceGbp: variant.selling_price_gbp,
      sellingPriceSek: variant.selling_price_sek,
      sellingPriceChf: variant.selling_price_chf,
      sellingPrices: variant.selling_prices,
    })),
    features: response.features as FashionLabsProductFeature[],
    segment: response.segment,
  };
};

export const productDtoToFashionLabsProductForImport = (
  response: ProductDtoToImportFashionLabsProduct,
): FashionLabsProductForImport => ({
  features: response.features as FashionLabsProductFeature[],
});
