import React, { FC, useMemo } from "react";

import "./price-management-outcome.css";
import { Table, TableBody, TableHeader } from "../../organisms/Table/Table";
import { Button, ButtonVariant, Text, TextVariant } from "@lookiero/react-ui-kit";
import { FileItem } from "../../../views/priceManagementOutcome/_behaviour/useExportCSV";
import { ReactIntl } from "@lookiero/i18n.js";
import {
  PRICE_MANAGEMENT_OUTCOME_I18N_PREFIX,
  PriceManagementOutcomeI18n,
} from "../../../../shared/i18n/priceManagementOutcome";

interface PriceManagementOutcomeProps {
  productsWithErrors: FileItem[];
  exportFile: (data: FileItem[], filename: string) => void;
}

const ErrorMessage: FC<{ id: string }> = ({ id }) => {
  const key = id.replace("styling.catalog.", "catalog.");
  return ReactIntl.useI18nMessage({ id: key });
};

const PriceManagementOutcome: FC<PriceManagementOutcomeProps> = ({ productsWithErrors, exportFile }) => {
  const parseProductsWithErrors = useMemo(() => {
    if (!productsWithErrors || !Array.isArray(productsWithErrors)) return [];
    return productsWithErrors.map((product) => {
      return {
        group: product.group,
        color: product.color,
        message: product.message,
      };
    });
  }, [productsWithErrors]);

  return (
    <div className="price-management-outcome-wrapper">
      <div className="price-management-outcome-content price-management-outcome-content__products_with_error">
        <div className="price-management-outcome-header price-management-outcome-header__header-with-button">
          <Text variant={TextVariant.HEADING_3}>
            <ReactIntl.I18nMessage
              id={PriceManagementOutcomeI18n.TITLE}
              prefix={PRICE_MANAGEMENT_OUTCOME_I18N_PREFIX}
            />
          </Text>
          <Button
            variant={ButtonVariant.PRIMARY}
            onClick={() => exportFile(parseProductsWithErrors, "ProductsWithErrors")}
          >
            <ReactIntl.I18nMessage
              id={PriceManagementOutcomeI18n.EXPORT_BUTTON}
              prefix={PRICE_MANAGEMENT_OUTCOME_I18N_PREFIX}
            />
          </Button>
        </div>
        {productsWithErrors && productsWithErrors.length > 0 && (
          <Table isSticky>
            <TableHeader>
              <div className="price-management-outcome-header__table-header">
                <div className="price-management-outcome-header__cell price-management-outcome-header__cell--group">
                  <Text variant={TextVariant.HEADING_3}>
                    <ReactIntl.I18nMessage
                      id={PriceManagementOutcomeI18n.GROUP}
                      prefix={PRICE_MANAGEMENT_OUTCOME_I18N_PREFIX}
                    />
                  </Text>
                </div>
                <div className="price-management-outcome-header__cell price-management-outcome-header__cell--color_code">
                  <Text variant={TextVariant.HEADING_3}>
                    <ReactIntl.I18nMessage
                      id={PriceManagementOutcomeI18n.COLOR}
                      prefix={PRICE_MANAGEMENT_OUTCOME_I18N_PREFIX}
                    />
                  </Text>
                </div>
                <div className="price-management-outcome-header__cell price-management-outcome-header__cell--currency">
                  <Text variant={TextVariant.HEADING_3}>
                    <ReactIntl.I18nMessage
                      id={PriceManagementOutcomeI18n.ERROR}
                      prefix={PRICE_MANAGEMENT_OUTCOME_I18N_PREFIX}
                    />
                  </Text>
                </div>
              </div>
            </TableHeader>
            <TableBody data={productsWithErrors}>
              {(item) => {
                return (
                  <div key={`${item.group}-${item.color}`}>
                    {item.group ? (
                      <article className="price-management-outcome-list-item">
                        <section className="price-management-outcome-list-item__cell price-management-outcome-list-item__cell--group">
                          {item.group}
                        </section>
                        <section className="price-management-outcome-list-item__cell price-management-outcome-list-item__cell--color_code">
                          {item.color}
                        </section>
                        <section className="price-management-outcome-list-item__cell price-management-outcome-list-item__cell--currency">
                          <ErrorMessage id={item.message} />
                        </section>
                      </article>
                    ) : (
                      <div>No data</div>
                    )}
                  </div>
                );
              }}
            </TableBody>
          </Table>
        )}
      </div>
    </div>
  );
};

export default PriceManagementOutcome;
